$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #F9F9F9;
$dark-grey: #4F4F4F;

.CASE_STUDY {

    .text-large {
        font-size: xx-large;
    }

    .vertical-spacer {
        border-right: 1px solid rgba(255,255,255,0.5);
    }

    .horizontal-spacer {
        display: none;
        border-top: 1px solid rgba(255,255,255,0.5);
    }

    .background-orange {
        background-color: $secondary;
    }

    .background-green {
        background-color: $primary;
    }

    .bullet-point {
        &::before {
            content: '\2022';
            color: orange;
            font-weight: bold;
            display: inline-block; 
            width: 1em; 
            margin-left: -1em;
        }
    }

    .header {
        background-image: url('../../assets/images/header_background_larger.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;

        .logo img {
            max-height: 140px;
        }

        .logo {
            background: unset;
        }

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }

        p {
            color: white;
        }

        p.text-green{
            color: $primary;
        }
    }

    .row.content {
        padding: 3% 10%;
    }

    .content {
        h2 {
            color: $dark-grey;
            margin-bottom: 2rem;
            font-family: "Muli Black";
            text-transform: uppercase;
        }

        h3 {
            font-family: "Muli Black";
            text-transform: uppercase;
            color: $dark-grey;
        }

        p {
            color: $grey;
            font-family: Muli;
        }

        .center-text {
            padding: 0 5rem;
        }

        .btn-primary {
            font-family: "Muli Black";
            text-transform: uppercase;
            font-size: 16px;
            letter-spacing: 1.41px;
            line-height: 20px;
            padding: 1rem 3rem 1rem 3rem;
            background-color: white;
            color: orange;
        }
    }

    .content.background-green, .content.background-orange {


        h2 {
            color: white;
            margin-bottom: 2rem;
        }

        .stat-number {
            font-size: 5rem;
            text-align: center;
        }

        .stat-text {
            font-size: 2rem;
            text-align: center;
            text-transform: capitalize;
        }

        p {
            color: white;
            font-family: Muli;
            letter-spacing: 1.24px;

            span {
                font-family: "Muli Black";
                font-size: 25px;
            }
        }

        .center-text {
            padding: 0 5rem;
        }
    }

    .content.background-grey {
        background-color: $light-grey;
        color: $dark-grey;

        img {
            height: 100%;
            width: 100%;
        }

        h2 {
            color: $dark-grey;
            margin-bottom: 2rem;
        }

        p {
            color: $grey;
            font-family: Muli;
            letter-spacing: 1.24px;

            span {
                font-family: "Muli Black";
                font-size: 25px;
            }
        }

        .center-text {
            padding: 0 5rem;
        }
    }

    .results-details {
        .row {
            padding: 5% 0;

            h3 {
                margin: 1.3rem 0;
                color: #737373;
                min-height: 35px;
            }

            img {
                max-height: 180px;
            }
        }
    }

    .testimonial {
        .col-sm-8 {
            background-image: url("../../assets/images/left-quote.svg");
            background-size: contain;
            background-repeat: no-repeat;

            p {
                &::before {
                    content: '"';
                }

                &::after {
                    content: '"';
                }

                font-family: "Muli Black";
                color: #919191;
                text-align: left;
            }
        }

        .col-sm-4 {
            img {
                max-height: 140px;
            }

            p{
                font-family: "Muli Black";
                color: #515151;
                margin-bottom: unset;
            }
        }
    }

}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .CASE_STUDY {
        .vertical-spacer {
            border-right: unset;
        }

        .horizontal-spacer {
            display: block;
        }

        .header {
            height: fit-content;
    
            .logo img {
                max-height: 90px;
            }
    
            h1 {
                color: white;
                font-size: 23px;
                letter-spacing: 2px;
            }

            p {
                font-size: small;
            }

            p.text-large {
                font-size: large;
            }
        }
    
        .row.content {
            padding: 3% 5%;
        }
    
        .content {
            .center-text {
                padding: unset;
            }
        }
    
        .content.background-green, .content.background-orange {
            h2 {
                text-align: center;
            }
    
            .center-text {
                padding: 0 5%;
            }
        }
    
        .content.background-grey {
            h2 {
                text-align: center;
            }
    
            .center-text {
                padding: 0 5%;
            }
        }
    
        .results-details {
            .row {
                padding: 5% 0;
    
                img {
                    height: 114px;
                }
            }
        }
    
        .testimonial {
            .col-sm-4 {
                text-align: center!important;
            }
        }
    
    }
}
.BLOG_POST {
    .header {
        background-image: url('../../../assets/images/header_background_larger.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 50vh;

        .logo img {
            max-height: 140px;
        }

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }

        p {
            color: white;
        }
    }

    .container {
        .row {
            .blog-post {  
                width: 300px;
                margin: auto;

                .tags {
                    color: #515151;
                    font-family: "Muli";
                    font-size: 12px;
                    font-style: italic;
                    margin-bottom: unset;
                    text-align: left;
                }

                .cover-img {
                    .overlay {
                        z-index: 0;
                        width: 300px;
                        height: 300px;
                        position: absolute;
                        border-radius: 4px;
                        background: linear-gradient(180deg, rgba(80,188,155,0) 0%, #50BC9B 100%)
                    }

                    h2 {
                        z-index: 1;
                        position: absolute;
                        bottom: 0;
                        color: white;
                        font-family: "Muli Black";
                        font-size: 16px;
                        text-align: left;
                        padding: 0 1rem;
                        text-transform: uppercase;
                    }

                    img {
                        width: 300px;
                        height: 300px;
                        border-radius: 4px;
                        object-fit: cover;
                    }
                }

                .content-snippet {
                    color: #515151;
                    font-family: "Muli";
                    font-size: 14px;
                    text-align: left;
                    margin-top: 1rem;
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .BLOG_POST {
        .header {
            height: fit-content;
    
            .logo img {
                max-height: 90px;
            }
    
            h1 {
                color: white;
                font-size: 23px;
                letter-spacing: 2px;
            }

            p {
                font-size: small;
            }

            p.text-large {
                font-size: large;
            }
        }
    }
}
$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.results {
    background-color: #f2f2f2;
    .text-center {
        h2 {
            padding: 1.5rem;
        }
    }
    .charts {
        padding: 3% 10%;
        justify-content: center;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;

        .column-container {
            align-items: center;
            display: flex;
            flex: 1 1 30%;
            flex-direction: column;

            .img {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
            }

            .percentage {
                font-size: 30px;
                font-weight: 400;
            }
            p {
                margin: 0.5rem 0;
                font-weight: 600;
                font-family: Muli;
                color: #636363;
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .results {
        .charts {
            padding: 3% 2%;
            .column-container {
                padding: 0 2%;
                text-align: center;
                .img {
                    flex-direction: column-reverse;

                    p {
                        margin-bottom: 0;
                        align-self: center;
                    }
                }

                p {
                    margin: 0;
                    padding: 0 0.5rem;
                    font-weight: 600;
                    font-size: 15px;
                    font-family: Muli;
                    color: #636363;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .results {
        .charts {
            padding: 3%;
            justify-content: center;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            .column {
                align-items: center;
                display: flex;
                flex-direction: column;

                .img {
                    display: flex;
                    flex-wrap: wrap;
                    align-items: flex-end;
                }

                .percentage {
                    font-size: 30px;
                    font-weight: 400;
                }
                p {
                    margin: 0.5rem 0;
                    font-weight: 600;
                    font-family: Muli;
                    color: #636363;
                }
            }
        }
    }
}

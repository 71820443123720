$primary: #50bc9b;
$secondary: #f58123;

.navbar {
    color: white;
    background-color: transparent !important;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: unset;
    z-index: 100;
    //dropdown menu
    .sub-menu {
        display: none;
        padding: 1rem 0 0 0;
        border-radius: 2px;
        margin: 0;
        flex-direction: column;
        list-style: none;
        position: absolute;
        top: 67%;
        right: 45%;
        z-index: 100;
        background-color: black;

        .nav-item {
            margin: 0.5rem 0;

            text-align: left;
        }
    }
    .sub-show {
        display: flex;
    }
    .dropdown-container {
        display: flex;
        flex-direction: column;
    }

    .navbar-brand {
        text-transform: uppercase;
        font-size: 25px;
        letter-spacing: 2.21px;
        padding-top: unset;

        .logo-background {
            background-color: white;
            display: inline-block;
            padding: 0.5rem;
            border-radius: 0 0 8px 8px;
        }

        img {
            max-height: 80px;
        }

        span {
            color: white;
        }
    }

    .navbar-toggler {
        background-color: white;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(80,188,155, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }
    }

    #navbarSupportedContent {
        ul {
            li {
                margin-left: 1.5rem;
                margin-right: 1.5rem;

                .nav-link {
                    font-family: "Muli Black";
                    color: white;
                    font-size: 14px;
                    letter-spacing: 1.24px;
                    padding-bottom: 5px;
                    border-bottom: 4px solid transparent;
                    border-radius: 2px;
                    cursor: pointer;
                        margin-bottom: 0;
                    &:hover {
                        color: $secondary;
                        border-bottom: 4px solid $secondary;
                    }
                }
            }
        }

        div.row {
            img {
                max-width: 34px;
                max-height: 34px;
                margin-left: 0.8rem;
                margin-right: 0.8rem;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .navbar {
        color: white;
        background-color: transparent !important;
        padding-left: unset;
        padding-right: unset;

        //dropdown menu
        .navbar-collapse .sub-menu {
            padding: 0 10%;
            top: 0;
            right: 0;
            background-color: unset;

            .nav-item {
                margin: 0.5rem 0;
                margin-left: 0;
                margin-right: 0;
                text-align: center;
            }
        }
        .navbar-brand {
            text-transform: uppercase;
            font-size: 20px;
            letter-spacing: 2px;
            margin-right: unset !important;
            margin-left: 1rem;

            img {
                max-height: 70px;
            }

            span {
                color: white;
            }
        }

        #navbarSupportedContent {
            background-color: rgba(0, 0, 0, 0.6);
            ul {
                z-index: 5;
                position: relative;
                border-radius: 0 15px 0 0;
                text-align: center;

                li {
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;

                    .nav-link {
                        color: white;
                        font-size: 14px;
                        letter-spacing: 1.24px;
                        padding-bottom: 5px;
                        border-bottom: 4px solid transparent;
                        border-radius: 2px;
                        margin-bottom: 0;
                    }
                }
            }

            div.row {
                padding: 0.5rem 0;
                border-radius: 0 0 15px 15px;

                .col {
                    flex: 1 1 20%;
                    padding: 1rem 0;
                }
                img {
                    max-height: 34px;
                    margin-left: 0.8rem;
                    margin-right: 0.8rem;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .navbar #navbarSupportedContent {
        background-color: transparent !important;
    }
}

// Tablet plus
@media only screen and (min-device-width: 1024px) and (max-device-width: 1560px) {
    .navbar #navbarSupportedContent {
        background-color: transparent !important;
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .navbar {
        color: white;
        background-color: transparent !important;
        padding-left: 5%;
        padding-right: 5%;

        //dropdown menu
        .navbar-collapse .sub-menu {
            padding: 0 10%;
            top: 0;
            right: 0;
            background-color: unset;
            .nav-item {
                margin: 0.5rem 0;
                margin-left: 0;
                margin-right: 0;
                text-align: center;
            }
        }
        .navbar-brand {
            text-transform: uppercase;
            font-size: 25px;
            letter-spacing: 2.21px;

            img {
                max-height: 80px;
            }

            span {
                color: white;
            }
        }

        #navbarSupportedContent {
            background-color: rgba(0, 0, 0, 0.6);

            ul {
                z-index: 5;
                position: relative;
                border-radius: 0 15px 0 0;
                text-align: center;

                li {
                    margin-left: 1.5rem;
                    margin-right: 1.5rem;

                    .nav-link {
                        color: white;
                        font-size: 14px;
                        letter-spacing: 1.24px;
                        padding-bottom: 5px;
                        border-bottom: 4px solid transparent;
                        border-radius: 2px;

                        &:hover {
                            color: $primary;
                            border-bottom: 4px solid $primary;
                        }
                    }
                }
            }

            div.row {
                padding: 0.5rem 0;
                border-radius: 0 0 15px 15px;
                .col {
                    text-align: center;
                }
                img {
                    max-height: 34px;
                    margin-left: 0.8rem;
                    margin-right: 0.8rem;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .navbar {
        padding-left: unset;
        padding-right: unset;

        //dropdown menu
        .navbar-collapse .sub-menu {
            padding: 1rem 0 0 0;
            border-radius: 2px;
            margin: 0;
            flex-direction: column;
            list-style: none;
            position: absolute !important;
            top: 90%;
            right: 20%;
            z-index: 100;
            background-color: black !important;

            .nav-item {
                margin: 0.5rem 0;

                text-align: left;
            }
        }
        #navbarSupportedContent {
            ul {
                background-color: unset;

                li {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }

            .socials {
                display: none;
            }
        }
    }
}

//Large Screen
@media only screen and (min-device-width: 2560px) {
    .navbar .sub-menu {
        right: 49.5%;
    }
}
//Large Screen
@media only screen and (min-device-width: 2736px) {
    .navbar .sub-menu {
        right: 50.5%;
    }
}
//Large Screen
@media only screen and (min-device-width: 4000px) {
    .navbar .sub-menu {
        right: 55.5%;
    }
}
// Small laptop
@media only screen and (min-device-width: 1200px) and (max-device-width: 1560px) {
    .navbar .sub-menu {
        right: 40%;
    }
}

$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #616161;

.OUR_PRICING {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    h2 {
        text-transform: uppercase;
    }

    .container {
        .col {
            display: flex;
            justify-content: center;
        }
        .card {
            padding-right: unset;
            padding-left: unset;
            max-width: 300px;
            min-height: 462px;

            .card-header {
                background-image: url('../../assets/images/card_header.png');
                background-size: cover;
                background-repeat: no-repeat;
                width: 100%;
                height: 130px;
                padding: unset;

                h3 {
                    color: white;
                    font-size: 35px;
                    line-height: 35px;
                    margin-bottom: unset;
                    margin-top: unset;
                }

                h4 {
                    color: white;
                    margin-bottom: unset;
                    
                    small {
                        font-size: 12px;
                        line-height: 12px;
                    }
                }
            }

            .card-body {
                p {
                    color: #707070;
                    font-family: Muli;
                    font-size: 14px;
                }

                .text-right {
                    padding-left: unset;
                    padding-right: unset;

                    p {
                        font-size: 20px;
                        line-height: 20px;
                        margin-bottom: unset;
                        color: $primary;
                        font-weight: bold;
                    }
                }

                .text-left {
                    padding-left: unset;
                    padding-right: unset;

                    p {
                        font-size: 13px;
                        line-height: 13px;
                        margin-bottom: unset;
                        color: $primary;
                    }
                }

                .btn-primary {
                    font-family: "Muli Black";
                    text-transform: uppercase;
                    font-size: 16px;
                    letter-spacing: 1.41px;
                    line-height: 20px;
                    padding: 1rem 3rem 1rem 3rem;
                }
            }
        }
    }

    .footer {
        margin-top: 2.5rem;
        margin-bottom: 2.5;

        p {
            font-size: 12px;
            font-family: Muli;
            line-height: 12px;
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {

}

@import "../admin.scss";

// Colors
$primary: #50BC9B;
$secondary: #F58123;
$text-light: white;
$text-dark: #454545;

.PostList {
    h1 {
        width: 100%;
        text-align: center;
    }

    .card {
        width: 100%;
        display: flex;
        background-color: white;
        border: 1px solid rgba(0,0,0,0.125);
        border-radius: 0.25rem;
        margin-bottom: 1rem;
        box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

        .card-body {
            flex: 1 1 auto;
            padding: 1.25rem;

            .btn-link {
                text-transform: capitalize;
                font-size: 2rem;
                margin-top: unset;
                margin-bottom: unset;

                text-decoration: none;
                color: $text-dark;

                &:hover { 
                    text-decoration: underline;
                }
                
            }

            h4 {
                margin-top: .2rem;
                margin-bottom: .75rem;
                font-weight: 400;
            }

            .card-text {
                display: none;
            }
        }
    }
}
.POST_LIST {
    .row {
        .blog-post {  
            width: 300px;
            margin: auto;

            .tags {
                color: #515151;
                font-family: "Muli";
                font-size: 12px;
                font-style: italic;
                margin-bottom: unset;
                text-align: left;
            }

            .cover-img {
                .overlay {
                    z-index: 0;
                    width: 300px;
                    height: 300px;
                    position: absolute;
                    border-radius: 4px;
                    background: linear-gradient(180deg, rgba(80,188,155,0) 0%, #50BC9B 100%)
                }

                h2 {
                    z-index: 1;
                    position: absolute;
                    bottom: 0;
                    color: white;
                    font-family: "Muli Black";
                    font-size: 16px;
                    text-align: left;
                    padding: 0 1rem;
                    text-transform: uppercase;
                }

                img {
                    width: 300px;
                    height: 300px;
                    border-radius: 4px;
                    object-fit: cover;
                }
            }

            .content-snippet {
                color: #515151;
                font-family: "Muli";
                font-size: 14px;
                text-align: left;
                margin-top: 1rem;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {}
@import "../admin.scss";

.BlogForm {
    .title {
        input {
            flex: 7 1;
        }

        select {
            flex: 1 1;
            margin-left: 1.5rem;
        }

        button[type=submit] {
            flex: 1 1 !important;
            margin-left: 1.5rem;
            margin-bottom: 5px;
            margin-top: unset;
        }
    }
}
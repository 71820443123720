$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.why-seo {
    background-image: url("../../assets/images/bg-why-seo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-right: 20%;

    .why-container {
        padding: 5% 10%;

        .text-left {
            h2 {
                margin-bottom: 1.5rem;
            }
        }
        .row {
            padding-right: 20%;

            .stat {
                margin: 2rem 0;
                border-left: 8px solid $primary;

                h3 {
                    font-size: 24px;
                    font-weight: 600;
                    color: $secondary;
                }
                p {
                    font-size: 20px;
                    font-weight: 600;
                    font-family: Muli;
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .why-seo {
        height: auto;
        padding: 0;
        .why-container {
            padding: 5% 0;
            width: 100%;

            .row {
                padding-right: 0;
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .why-seo {
        height: 70vh;
        padding-right: 0;

        .why-container {
            padding: 5% 10%;

            .row {
                padding-right: 0;
            }
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.websiteDevelopment {
    .btn-primary {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
    .header {
        background-image: url("../../assets/images/header_background_larger.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }
    .why-do-i-need {
        flex: 1 1 100%;
        padding: 3% 10%;

        .padded-row {
            padding: 0 5%;

            img {
                transform: translateX(-100px);
            }
        }
        .container-row {
            align-items: stretch;

            .col {
                flex: 1 1 30%;
                padding: 0;
                justify-content: flex-start;

                h2 {
                    margin-bottom: 1rem;
                }
            }
            .deluxe {
                justify-content: flex-start;
                margin: 0 1.5rem;
            }

            .image-col {
                flex: 1 1 65%;
                justify-content: center;

                img {
                    max-width: 110%;
                }
                .deluxe-image {
                    max-width: 100%;
                }
            }
        }
    }

    .why-use {
        flex: 1 1 100%;
        padding: 3% 10%;
        background-color: #f2f2f2;

        .header-container {
            margin: 2rem 0;
        }

        .container {
            align-items: flex-end;

            .social-item {
                flex-direction: column;
                flex: 1 1 20%;
                margin: 0 1rem;

                h3 {
                    font-size: 34px;
                    margin: 1rem 0;
                    font-weight: 600;
                    color: $secondary;
                }
                p {
                    text-align: center;
                    font-family: Muli;
                    color: $text-primary;
                }
            }
        }
        .footer-container {
            margin: 2rem 0;
            background-color: #f2f2f2;
        }
    }
    .how-we-help {
        flex: 1 1 100%;
        padding: 3% 15%;

        .container-row {
            align-items: stretch;

            .col-wide {
                flex: 1 1 100%;
            }
            .padded {
                padding-right: 60%;
            }
            .col {
                justify-content: flex-start;
                flex: 1 1 40%;

                p {
                    padding-right: 20%;
                }
            }
            .col-image {
                flex: 1 1 55%;
                justify-content: center;
            }
        }
        .reverse {
            flex-direction: row-reverse;
            .col {
                justify-content: flex-start;
            }
            .col-image {
                flex: 1 1 55%;
                justify-content: flex-end;

                img {
                    max-width: 120%;
                }
            }
        }
        .footer-container {
            margin-top: 2rem;
            padding: 0 35%;
            background-color: white;
        }
    }
    .whatWeDo {
        background-image: url("../../assets/images/bg-what-we-do1.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        .header-container,
        .footer-container {
            margin: 2rem 0;
            background-color: unset;
        }
        .card {
            background-color: white;

            p {
                color: unset;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .websiteDevelopment {
        .why-do-i-need {
            padding: 3% 5%;

            .padded-row {
                padding: 0;

                img {
                    transform: translateX(0);
                }
            }
            .container-row {
                margin: 1rem 0;
                text-align: center;

                .image-col {
                    img {
                        max-width: 100%;
                    }
                }
                .col {
                    justify-content: center;
                    flex: 1 1 90%;
                }
            }
            .mobile-reverse {
                flex-direction: column-reverse;
                align-items: center;
                
                a {
                    margin: 1rem 0;
                }
                
                .image-col {
                    margin: 1rem 0;

                    img {
                        max-width: 100%;
                    }
                }
            }
        }
        .why-use {
            padding: 3%;

            .header-container {
                margin: 1rem 0;
                text-align: center;
            }

            .container {
                .social-item {
                    flex: 1 1 90%;
                    margin: 1rem;
                }
            }
        }
        .how-we-help {
            padding: 3% 5%;

            .container-row {
                text-align: center;

                .padded {
                    padding: unset;
                
                    h2 {
                        margin: 1rem 0;
                    }
                }
                .col {
                    margin: 1rem 0;
                    justify-content: center;
                    flex: 1 1 90%;
                    
                    a {
                        margin: 1rem 0;
                    }

                    p {
                        padding: 0;
                    }
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .websiteDevelopment {
        .why-do-i-need {
            padding: 3% 5%;

            .padded-row {
                padding: 0;

                img {
                    transform: translateX(0);
                }
            }
            .container-row {
                margin: 1rem 0;
                .col {
                    flex: 1 1 35%;
                }
                .image-col {
                    flex: 1 1 60%;

                    img {
                        max-width: 110%;
                    }
                }
            }
            .mobile-reverse {
                .col {
                    flex: 1 1 35%;
                }
                .image-col {
                    flex: 1 1 50%;
                }
            }
        }
        .why-use {
            padding: 3% 5%;

            .header-container {
                text-align: center;
            }

            .container {
                .social-item {
                    flex: 1 1 40%;
                }
            }
        }
        .how-we-help {
            padding: 3% 5%;

            .container-row {
                .padded {
                    padding: unset;
                }
                .col {
                    margin: 1rem 0;
                    justify-content: center;
                    flex: 1 1 40%;
                    p {
                        padding: 0;
                    }
                }
                .col-image {
                    flex: 1 1 55%;
                }
            }
            .reverse {
                .col-image {
                    justify-content: flex-end;
                }
            }
        }
    }
}

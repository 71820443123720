// Colors
$primary: #50BC9B;
$secondary: #F58123;
$text-light: white;
$text-dark: #454545;

:root {
    font-size: 16px;
    font-family: 'Montserrat';
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
    --transition-speed: 600ms;
}
  
body {
    color: $text-dark;
    background-color: white;
    margin: 0;
    padding: 0;

    main {
        margin-left: 5rem;
        padding: 1rem;
        z-index: 1;
    }

    
}

body::-webkit-scrollbar {
    width: 0.25rem;
}
  
body::-webkit-scrollbar-track {
    background: #1e1e24;
}
  
body::-webkit-scrollbar-thumb {
    background: $primary;
}

// Theme Options
.dark {
    --text-primary: #b6b6b6;
    --text-secondary: #ececec;
    --bg-primary: #23232e;
    --bg-secondary: #141418;
}

.light {
    --text-primary: #1f1f1f;
    --text-secondary: #000000;
    --bg-primary: #ffffff;
    --bg-secondary: #e4e4e4;
}

.solar {
    --text-primary: #576e75;
    --text-secondary: #35535c;
    --bg-primary: #fdf6e3;
    --bg-secondary: #f5e5b8;
}

.theme-icon {
    display: none;
}

.dark #darkIcon {
    display: block;
}

.light #lightIcon {
    display: block;
}

.solar #solarIcon {
    display: block;
}

.Admin {

    .text-muted {
        color: #6c757d!important;
    }

    //  Form Styles
    form {
        width: 100%;

        .form-group {
            display: flex;
            flex-flow: row wrap;

            label {
                margin: 5px 10px 5px 0;
                flex: 0 0 100%;
            }

            input {
                padding: .375rem .75rem;
                flex: 0 0 100%;
                box-sizing: border-box;
                border: 2px solid #EDEDED;
                border-radius: 5px;
                margin: 5px 0px;
                border-radius: .25rem;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }

            select {
                padding: .375rem .75rem;
                flex: 0 0 100%;
                box-sizing: border-box;
                border: 2px solid #EDEDED;
                border-radius: 5px;
                margin: 5px 0px;
                border-radius: .25rem;
                transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }

            button[type=submit] {
                flex: 0 0 100%;
                margin: 1rem 0;
            }
        }
    }
    // Button Styles
    .btn-primary {
        background-color: $primary;
        font-family: 'Montserrat';
        font-size: 1.2rem;
        font-weight: 600;
        border-color: $primary;
        border-radius: 5px;
        color: $text-light;
        padding: 0.5rem 1rem;
        transition: background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;

        &:hover { 
            background-color: $secondary;
            border-color: $secondary;
        }

        &:active { 
            text-decoration: underline;
        }

        &:focus { 
            text-decoration: underline;
            box-shadow: 0 0 0 0.2rem rgba($secondary,.5);
        } 
    }

    .btn-link {
        background-color: transparent;
        font-family: 'Montserrat';
        border: unset;
        color: $text-dark;
        font-weight: 700;
        text-decoration: underline;
    }
}

/* Small screens */
@media only screen and (max-width: 600px) {
    body {
        main {
            margin-left: unset;
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.SocialMedia {
    .header {
        background-image: url("../../assets/images/header_background_larger.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }
    .row.content {
        padding: 3% 10%;
        justify-content: center;

        .text-center {
            h2 {
                margin-bottom: 2.5rem;
            }
            a {
                margin-top: 2.5rem;
            }
        }

        .block-container {
            p {
                font-family: Muli;
                color: $text-primary;
            }
            img {
                margin: 1rem 0;

                .block-container {
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .why-social-media {
        flex: 1 1 100%;
        padding: 3% 10%;
        background-color: #f2f2f2;

        .header-container {
            margin: 2rem 0;
        }

        .social-item {
            flex-direction: column;
            flex: 1 1 20%;
            margin: 0 1rem;

            h3 {
                font-size: 34px;
                margin: 1rem 0;
                font-weight: 600;
                color: $secondary;
            }
            p {
                text-align: center;
                font-family: Muli;
                color: $text-primary;
            }
        }
        .footer-container {
            margin: 2rem 0;
            background-color: #f2f2f2;
        }
    }
    .how-we-help {
        flex: 1 1 100%;
        padding: 3% 10%;

        p {
            padding: 0 20%;
        }
        .container-row {
            align-items: stretch;

            .col {
                flex: 1 1 40%;

                .absolute {
                    position: absolute;
                    top: -150px;
                    right: -150px;
                }
            }
        }
        .footer-container {
            margin-top: 2rem;
            padding: 0 35%;
            background-color: white;

            a {
                margin: 1rem 0;
            }

            p {
                padding: unset;
            }
        }
    }
    .what-we-do {
        background-image: url("../../assets/images/bg-what-we-do.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        .header-container {
            padding: 4rem 0 2rem 0;
        }

        .card-row {
            flex: 1 1 100%;
            padding: 0 10%;

            .card {
                display: flex;
                flex-direction: column;
                flex: 1 1 20%;
                align-content: center;
                justify-content: center;
                min-height: 175px;
                margin: 1rem 1.25rem;
                padding: 1rem;
                border-radius: 0px;

                p {
                    font-weight: 600;
                    margin: 1rem 0;
                }
            }
            :hover {
                background-color: whitesmoke;
            }
        }
        .footer-container {
            background-color: unset;
            padding: 2rem 0 4rem 0;
        }
    }
    .our-results {
        background-color: $secondary;
        flex: 1 1 100%;
        padding: 3% 10%;
        flex-direction: column;

        .header-container {
            margin: 2.5rem 0;
        }

        .container-row {
            display: flex;
            width: 100%;

            .flex {
                flex: 1 1 100%;
                .result-item {
                    flex-direction: column;
                    color: white;
                    flex: 1 1 30%;
                    margin: 0 1rem;
                    h3 {
                        font-size: 34px;
                        margin: 1rem 0;
                        font-weight: 600;
                    }
                    .primary {
                        text-align: center;
                        color: white;
                    }
                }
            }
        }
    }
    .btn-primary {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .SocialMedia {
        .row.content {
            padding: 5% 10%;

            .text-center {
                padding: 0;
                img {
                    margin: 2rem 0;
                }
            }

            .block-container {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        .why-social-media {
            padding: 3%;

            .header-container {
                margin: 1rem 0;
                text-align: center;
            }

            .social-item {
                flex: 1 1 90%;
                margin: 0.5rem 1rem;
            }
        }
        .how-we-help {
            padding: 3% 5%;
            text-align: center;

            p {
                padding: unset;
            }
            .header-container {
                margin: 1rem 0;
            }
            .container-row {
                margin: 2rem 0;
                .col {
                    flex: 1 1 90%;

                    .absolute {
                        display: none;
                    }
                }
            }
            .footer-container {
                padding: 0;

                a {
                    margin: 1rem 0;
                }
            }
            .reverse {
                flex-direction: column-reverse;
            }
        }
        .what-we-do {
            .header-container {
                padding: 2rem 0;
            }

            .card-row {
                padding: 0 5%;

                .card {
                    flex: 1 1 80%;
                }
            }
            .footer-container {
                padding: 2rem 0;
            }
        }
        .our-results {
            padding: 3% 5%;

            .header-container {
                margin: 1rem 0;
            }
            .container-row {
                .flex {
                    .result-item {
                        flex: 1 1 90%;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .SocialMedia {
        .row.content {
            padding: 3% 1%;

            .text-center {
                a {
                    margin: 2rem 0;
                }
            }
        }
        .why-social-media {
            padding: 3% 5%;

            .header-container {
                text-align: center;
            }

            .social-item {
                flex: 1 1 40%;
            }
        }
        .how-we-help {
            p {
                padding: unset;
            }
            .container-row {
                .col {
                    .absolute {
                        top: -94px;
                        right: -90px;
                        width: 60%;
                        z-index: -10;
                    }
                }
            }
            .footer-container {
                padding: 0 15%;
            
                a {
                    margin: 2rem 0;
                }
            }
        }
        .what-we-do {
            .card-row {
                padding: 0 10%;

                .card {
                    flex: 1 1 40%;
                }
            }
            .footer-container {
                padding: 3rem 0;
            }
        }
        .our-results {
            padding: 3% 5%;

            .container-row {
                .flex {
                    .result-item {
                        flex: 1 1 30%;
                        margin: 1rem 0;
                    }
                }
            }
        }
    }
}

@font-face {
    font-family: 'Muli Black';
    src: url('./assets/fonts/Muli-Black.ttf') format('truetype');
} 

@font-face {
    font-family: 'Muli';
    src: url('./assets/fonts/Muli-Regular.ttf') format('truetype');
} 

// Colors
$primary: #50BC9B;
$secondary: #F58123;
$text-light: white;
$text-dark: #454545;

/*
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
*/

body {
    font-family: "Muli Black";
    background-color: #FCFCFC;
}

.container-fluid {
    padding-left: unset;
    padding-right: unset;
}

.row {
    margin-left: unset;
    margin-right: unset;
}

h2 {
    font-family: "Muli Black";
    font-size: 25px;
    letter-spacing: 2.21px;
}

h3 {
    font-family: "Muli Black";
    font-size: 14px;
    letter-spacing: 1.24px;
}

.show-mobile-only {
    display: none;
}

.show-everything-but-mobile {
    display: block;
}

//  Custom classes
.primary {
    font-family: Muli;
    color: #636363;
}
.flex {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.nav-container {
    z-index: 5 !important;
}
.btn-primary {
    background-color: $secondary;
    border-color: $secondary;
    border-radius: 100px;
    padding: 0.2rem 2rem 0.2rem 2rem;
    transition: background-color 0.5s ease-out;

    &:hover { 
        color: $secondary;
        border-color: $secondary;
        background-color: white;
    }

    &:active { 
        color: $primary;
        background-color: $secondary;
        border-color: $secondary;
        text-decoration: underline;
    }

    &:focus { 
        color: $primary;
        background-color: $secondary;
        border-color: $secondary;
        text-decoration: underline;
        box-shadow: 0 0 0 0.2rem rgba(245,129,35,.5);
    }

    
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: $primary;
    border-color: $secondary;
    background-color: white;
}

.btn-primary-inverted {
    background-color: white;
    border-color: white;
    border-radius: 100px;
    padding: 0.2rem 2rem 0.2rem 2rem;
    color: $secondary;
    transition: background-color 0.5s ease-out;

    &:hover { 
        border-color: white;
        background-color:$secondary;
        color: white;
    }

    &:active { 
        border-color: white;
        background-color:$secondary;
        color: white;
        text-decoration: underline;
    }
}

.text-orange {
    color: $secondary;
}

.text-green {
    color: $primary;
}

// Animations [Start]
.bounce {
    animation-delay: 2s;
	-webkit-animation: bounce 2s;
	        animation: bounce 2s;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}

@keyframes bounce {
	0%,
	25%,
	50%,
	75%,
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	40% {
		-webkit-transform: translateY(-20px);
		        transform: translateY(-20px);
	}
	60% {
		-webkit-transform: translateY(-12px);
		        transform: translateY(-12px);
	}
}
// Animations [End]

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .show-mobile-only {
        display: block;
    }
    
    .show-everything-but-mobile {
        display: none;
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {}

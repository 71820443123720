$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.paidSearch {
    .btn-primary {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
    .header {
        background-image: url("../../assets/images/header_background_larger.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }
    .row.content {
        padding: 3% 10%;
        justify-content: center;

        .text-center {
            h2 {
                margin: 2rem 0 3rem 0;
            }
            a {
                margin-top: 1.5rem;
            }
        }

        .text-left {
            p {
                font-family: Muli;
                color: $text-primary;
            }
            img {
                margin: 1rem 0;

                .block-container {
                    margin-bottom: 2rem;
                }
            }
        }
    }

    .why-use {
        flex: 1 1 100%;
        padding: 3% 10%;
        background-color: #f2f2f2;

        .header-container {
            margin: 2rem 0;
        }

        .container {
            align-items: flex-end;

            .social-item {
                flex-direction: column;
                flex: 1 1 20%;
                margin: 0 1rem;

                h3 {
                    font-size: 34px;
                    margin: 1rem 0;
                    font-weight: 600;
                    color: $secondary;
                }
                p {
                    text-align: center;
                    font-family: Muli;
                    color: $text-primary;
                }
            }
        }
        .footer-container {
            margin: 2rem 0;
            background-color: #f2f2f2;
        }
    }
    .how-we-help {
        flex: 1 1 100%;
        padding: 3% 20%;

        .header-container {
            margin: 2rem 0;
        }

        p {
            padding: 5% 10%;
        }
        .container-row {
            align-items: stretch;

            .col {
                justify-content: flex-start;
                flex: 1 1 40%;

                .item {
                    background-repeat: no-repeat;
                    background-size: auto;
                }
                .one {
                    background-image: url("../../assets/images/1.png");
                }
                .two {
                    background-image: url("../../assets/images/2.png");
                    margin-left: 15%;

                    p {
                        padding: 5% 0 5% 15%;
                    }
                    .arrowRight {
                        position: absolute;
                        top: 27%;
                        left: 0;
                    }
                    .arrowLeft {
                        position: absolute;
                        bottom: 27%;
                        left: 0;
                    }
                }
                .three {
                    background-image: url("../../assets/images/3.png");
                }
            }
        }
        .footer-container {
            margin-top: 2rem;
            padding: 0 35%;
            background-color: white;
        }
    }
    .whatWeDo {
        background-image: url("../../assets/images/what-we-do-bg.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        .card {
            background-color: white;

            p {
                color: unset;
            }
        }
    }
    .results {
        background-color: white;
        padding: 3% 0;
        .header-container {
            margin: 2rem 0 4rem 0;
        }
        .container-row {
            padding: 0 20%;
            justify-content: space-evenly;

            .inner-col {
                flex: 1 1 30%;
                .column {
                    flex-direction: column;
                    text-align: center;

                    .img {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                    h3 {
                        font-family: Muli;
                        font-size: 36px;
                        margin-top: 1rem;
                        font-weight: 500;
                    }
                    p {
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .paidSearch {
        .row.content {
            padding: 3% 5%;

            .block-container {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;
            }
            .text-center {
                a {
                    margin: 1rem 0;
                }
            }
        }
        .why-use {
            padding: 3%;

            .header-container {
                text-align: center;
            }

            .container {
                .social-item {
                    flex: 1 1 90%;
                    margin: 1rem;
                }
            }
        }
        .how-we-help {
            flex: 1 1 100%;
            padding: 3%;

            p {
                padding: 0;
            }
            .container-row {
                flex-direction: column-reverse;
                text-align: right;

                .col {
                    justify-content: center;
                    flex: 1 1 90%;

                    .two {
                        margin-left: 0;

                        p {
                            padding: 5% 0 5% 15%;
                        }
                        .arrowRight {
                            top: 20%;
                        }
                        .arrowLeft {
                            bottom: 20%;
                        }
                    }
                }
            }
            .footer-container {
                margin: 2rem 0;
                padding: 0;
            }
        }
        .results {
            .header-container {
                margin: 1rem 0;
            }
            .container-row {
                padding: 0 5%;
                justify-content: center;

                .inner-col {
                    flex: 1 1 90%;
                    margin: 1rem 0;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .paidSearch {
        .row.content {
            padding: 3% 1%;
        }
        .why-use {
            padding: 3% 5%;

            .header-container {
                text-align: center;
            }

            .container {
                .social-item {
                    margin: 2rem 1rem;
                    flex: 1 1 40%;
                }
            }
        }
        .how-we-help {
            padding: 3% 5%;

            p {
                padding: 0 5%;
            }
            .container-row {
                margin: 1rem 0;
                .col {
                    justify-content: flex-start;
                    flex: 1 1 40%;
                }
            }
            .footer-container {
                padding: 0 15%;
            }
        }
        .results {
            .container-row {
                padding: 0 5%;

                .inner-col {
                    flex: 1 1 30%;
                }
            }
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;

.get-started-container {
    background-color: $primary;

    .get-started {
        display: flex;
        padding: 3% 10% 0 10%;
        align-items: center;
        justify-content: center;

           
        .btn-primary-inverted {
            background-color: $secondary;
            border-color: $secondary;
            color: white;
            padding: 0.5rem 2rem 0.5rem 2rem;

        }
        :hover {
            border-color: white;
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .get-started-container {
        .get-started {
            padding: 3% 10%;
            flex-direction: column;

            img {
                width: 70%;
            }
            .text-container {
                margin: 2rem 0;
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .get-started-container {
        .get-started {
            img {
                width: 40%;
            }
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;

.CONTACT {
    display: flex;

    .background-img {
        background-image: url("../../assets/images/contact_bg.svg");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 400px;
        width: 100%;
    }

    .background-img-full {
        background-image: url("../../assets/images/contact_bg_full.svg");
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 90vh;
        width: 100%;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            margin-bottom: 4rem;
        }
    }

    .container {
        flex: 1 1 auto;
        align-items: center;

        .row {
            flex: 1 1 auto;

            .col-sm-4 {
                .card {
                    max-width: 260px;
                    border-radius: 18px;
                    height: 180px;
                    transition: opacity 2s ease-out;
                    margin: auto;

                    .card-body {
                        p {
                            color: $primary;
                            font-size: 20px;
                            font-family: "Muli Black";
                            letter-spacing: 1.77px;
                            margin-top: 1.5rem;
                            margin-bottom: unset;
                        }
                    }

                    .contact-form {
                        display: none;
                        flex: 1 1 auto;
                        min-height: 1px;
                        padding: 1.25rem;

                        label {
                            color: #798897;
                            font-family: "Muli";
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 1.05px;
                        }

                        h2 {
                            color: #707070;
                            font-family: "Muli Black";
                            font-size: 14px;
                            line-height: 14px;
                        }

                        a {
                            color: #707070;
                            font-family: "Muli";
                            font-size: 13px;
                            font-weight: bold;
                            letter-spacing: 1px;
                        }
                    }

                    &:hover {
                        .card-body {
                            display: none;
                        }

                        .contact-form {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .usa {
                                margin-top: 0.25rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .CONTACT {
        .background-img-full {
            h1 {
                padding-top: 2rem;
                top: unset;
                transform: unset;
            }
        }

        .container {
            top: unset;
            transform: unset;

            .col-sm-4 {
                margin-bottom: 1rem;

                .card {
                    max-width: 260px;
                    height: unset;
                    border-radius: 18px;
                    margin-bottom: 2rem;

                    .card-body {
                        img {
                            max-width: 75%;
                        }

                        p {
                            font-size: 14px;
                            font-family: "Muli Black";
                            letter-spacing: unset;
                        }
                    }
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .CONTACT {
        .container {
            .col-sm-4 {
                .card {
                    max-width: 260px;
                    border-radius: 18px;
                    margin-bottom: 2rem;

                    .card-body {
                        img {
                            max-width: 75%;
                        }

                        p {
                            font-size: 14px;
                            font-family: "Muli Black";
                            letter-spacing: unset;
                        }
                    }
                }
            }
        }
    }
}

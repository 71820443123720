$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.whatWeDo {
    background-image: url("../../assets/images/bg-what-we-do-seo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 3% 5%;

    .cards-container {
        padding: 0 5%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        .card {
            flex-basis: unset;
            width: 15%;
            padding: 1.5rem 0;
            margin: 1rem 1.25rem;
            background-color: #50bc9b;
            display: flex;
            flex-direction: column;

            p {
                font-weight: 600;
                margin: 1rem 0;
                font-family: Muli;
                color: white;
            }
        }
    }
        a {
            margin: 1rem 0;
        }
}
// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .whatWeDo {
        height: auto;
        .text-center {
            margin: 1rem 0;
        }
        .cards-container {
            .card {
                width: 100%;
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .whatWeDo {
        height: auto;
        padding: 3% 5%;

        .cards-container {
            justify-content: space-between;
            padding: 0;
            
            .card {
                width: 17%;
                padding: 1.5rem 0;
                margin: 1rem 0.5rem;
            }
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.howWeHelp {
    .help-container {
        margin: 2rem 0;

        .row {
            padding: 1rem 20%;

            .item {
                p {
                    margin: 1rem;
                    font-family: Muli;
                    color: #636363;
                }
                a {
                    margin: 1rem;
                }
            }
        }
    }
}
// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .howWeHelp {
        .help-container {
            .row {
                padding: 1rem;
                display: flex;
                flex-direction: column-reverse;
                text-align: center;

                .item {
                    margin: 0.5rem 0;
                    width: 100%;
                    max-width: 100%;
                    flex: 1 1 100%;
                    padding: 0;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    a {
                        margin: 1rem;
                    }
                    p {
                        margin: 0;
                    }
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .howWeHelp {
        .help-container {
            .row {
                padding: 1rem 2%;

                .item {
                    p {
                        margin: 1rem;
                        font-family: Muli;
                        color: #636363;
                    }
                    a {
                        margin: 1rem;
                    }
                }
            }
        }
    }
}

$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.SEO {
    .header {
        background-image: url("../../assets/images/header_background_larger.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }
    .row.content {
        padding: 3% 10%;
        justify-content: center;

        .text-center {
            h2 {
                margin-bottom: 1.5rem;
            }
            a {
                margin-top: 1.5rem;
            }
        }

        .text-left {
            p {
                font-family: Muli;
                color: $text-primary;
            }
            img {
                margin: 1rem 0;

                .block-container {
                    margin-bottom: 2rem;
                }
            }
        }
    }
    .btn-primary {
        padding: 0.5rem 2rem 0.5rem 2rem;
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .SEO {
        .row.content {
            padding: 5%;

            .block-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .SEO {
        .row.content {
            padding: 3% 1%;
        }
        .why-seo {
            height: unset;
            background-position-x: 30%;

            .why-container {
                padding: 5% 10%;

                .row {
                    padding-right: 0;
                }
            }
        }
    }
}

$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #616161;

.OUR_CLIENTS_SAY {
    margin-top: 4.5rem;
    margin-bottom: 2.5rem;

    h2 {
        margin-bottom: 2rem;
        font-family: "Muli Black";
        font-size: 25px;
        letter-spacing: 2.21px;
        text-transform: uppercase;
    }

    .carousel-indicators {
        li {
            background-color: #D6D6D6;

            &:active {
                background-color: $primary;
            }
        }

        .active {
            background-color: $primary;
        }
    }

    .carousel-inner {

        .carousel-item {
            padding: 2rem;

            .rating {
                margin: 1rem 0rem;
            }

            p {
                font-family: Muli;
                font-size: 12px;
                letter-spacing: 0.26px;
                color: $light-grey;
            }

            p.text-green{
                font-size: 14px;
                color: $primary;
                font-weight: bold;
                letter-spacing: 0.3px;
            }

            .logo {
                height: 165px;
                max-width: 60%;
                background-color: unset;
                width: unset;
            }
        }
    }
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .OUR_CLIENTS_SAY {
        .carousel-inner {
            .carousel-item {
                padding: 1rem;
    
                .logo {
                    height: 165px;
                    max-width: 100%;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .OUR_CLIENTS_SAY {
        .carousel-inner {
            .carousel-item {
                padding: 1rem;
    
                .logo {
                    height: 165px;
                    max-width: 100%;
                }
            }
        }
    }
}
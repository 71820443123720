$primary: #50bc9b;
$secondary: #f58123;
$grey: #707070;
$light-grey: #616161;
$text-primary: #636363;

.contentCreation {
    .header {
        background-image: url("../../assets/images/header_background_larger.png");
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }
    .what-is-content-marketing {
        flex: 1 1 100%;
        padding: 3% 10%;

        .header-container {
            margin: 2rem 0;
        }
        .container-row {
            flex: 1 1 100%;
            padding: 0 20%;

            .content {
                flex: 1 1 100%;
            }
            .top {
                padding-right: 50%;
            }
            .middle {
                flex: 0 1 70%;
            }
            .bottom {
                padding-left: 50%;
            }
        }
        .footer-container {
            background-color: unset;
            margin: 2rem 0;
        }
    }
    .why-use {
        flex: 1 1 100%;
        padding: 3% 10%;
        background-color: #f2f2f2;

        .header-container {
            margin: 2rem 0;
        }

        .container {
            align-items: flex-end;

            .social-item {
                flex-direction: column;
                flex: 1 1 20%;
                margin: 0 1rem;

                h3 {
                    font-size: 34px;
                    margin: 1rem 0;
                    font-weight: 600;
                    color: $secondary;
                }
                p {
                    text-align: center;
                    font-family: Muli;
                    color: $text-primary;
                }
            }
        }
        .footer-container {
            margin: 2rem 0;
            background-color: #f2f2f2;
        }
    }
    .how-we-help {
        flex: 1 1 100%;
        padding: 3% 20%;

        .header-container {
            margin: 2rem 0;
        }

        p {
            padding: 0 20%;
        }
        .container-row {
            align-items: stretch;

            .col {
                justify-content: flex-start;
                flex: 1 1 40%;
            }
        }
        .reverse {
            flex-direction: row-reverse;
            .col {
                justify-content: flex-end;
            }
        }
        .footer-container {
            margin-top: 2rem;
            padding: 0 35%;
            background-color: white;
        }
    }
    .whatWeDo {
        background-image: url("../../assets/images/bg-what-we-do1.jpg");
        background-repeat: no-repeat;
        background-size: cover;

        .header-container, .footer-container {
            margin: 2rem 0;
            background-color: unset;
        }
        .card {
            background-color: white;

            p {
                color: unset;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width: 0px) and (max-device-width: 480px) {
    .contentCreation {
        .what-is-content-marketing {
            padding: 3% 5%;
            text-align: center;

            .container-row {
                padding: 0 2%;

                .content {
                    flex: 1 1 100%;
                }
                .top {
                    padding-right: 0;
                }
                .middle {
                    margin: 1rem 0;
                    flex: 1 1 100%;
                }
                .bottom {
                    padding-left: 0;
                }
            }
        }
        .why-use {
            padding: 3%;

            .header-container {
                text-align: center;
            }

            .container {
                .social-item {
                    flex: 1 1 90%;
                    margin: 1rem;
                }
            }
        }
        .how-we-help {
            padding: 3% 5%;

            p {
                padding: 0;
            }

            .container-row {
                text-align: center;

                .col {
                    margin: 1rem 0;
                    justify-content: center;
                    flex: 1 1 90%;
                }
            }
            .footer-container {
                padding: 0;
            
                a {
                    margin: 1rem 0;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .contentCreation {
        .what-is-content-marketing {
            padding: 3% 10%;

            .container-row {
                flex: 1 1 100%;
                padding: 0 5%;

                .content {
                    flex: 1 1 100%;
                }
                .top {
                    padding-right: 20%;
                }
                .middle {
                    margin: 1rem 0;
                    flex: 1 1 100%;
                }
                .bottom {
                    padding-left: 20%;
                }
            }
        }
        .why-use {
            padding: 3% 5%;

            .header-container {
                text-align: center;
            }

            .container {
                .social-item {
                    flex: 1 1 40%;
                }
            }
        }
        .how-we-help {
            padding: 3% 10%;

            p {
                padding: 0 5%;
            }
            .container-row {
                margin: 1rem 0;
                .col {
                    justify-content: flex-start;
                    flex: 1 1 40%;
                }
            }
            .footer-container {
                padding: 0 15%;
            
                a {
                    margin: 2rem 0;
                }
            }
        }
    }
}

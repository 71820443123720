$primary: #50BC9B;
$secondary: #F58123;

/*
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
*/

.home {
    .header {
        position: relative;
        background-color: black;
        height: 75vh;
        min-height: 25rem;
        width: 100%;
        overflow: hidden;

        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: black;
            opacity: 0.7;
            z-index: 1;
        }

        video {
            position: absolute;
            top: 50%;
            left: 50%;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            z-index: 0;
            -ms-transform: translateX(-50%) translateY(-50%);
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
        }

        .align-self-top, .align-self-center, .align-self-end {
            position: relative;
            z-index: 2;

            .btn-primary {
                font-size: 45px;
                letter-spacing: 3.98px;
                text-transform: uppercase;
            }

            p {
                color: white;
                font-size: 20px;
                letter-spacing: 1.77px;
                font-family: Muli;
            }
        }

        .align-self-end {
            img {
                margin-bottom: 2rem;
            }
        }
    }

    .PAYG_IS {
        margin-top: 2.5rem;
        margin-bottom: 2.5rem;

        .text-right{
            p {
                font-size: 16px;
                letter-spacing: 1.41px;
                margin-right: 2rem;
                margin-top: 2.5rem;
                color: #4F4F4F;
                text-transform: uppercase;
            }
        }

        .text-center {
            img {
                margin-top: 10%;
                transform: rotate(-45deg);
            }
        }

        .text-left {
            p {
                font-size: 16px;
                letter-spacing: 1.41px;
                margin-left: 2rem;
                margin-top: 2.5rem;
                color: #4F4F4F;
                text-transform: uppercase;
            }
        }
    }

    .PAYG_SERVICES {
        margin-top: 2rem;
        margin-bottom: 2rem;

        h2 {
            margin-bottom: 2.5rem;
        }

        .col-sm.col-6.text-center {
            img {
                max-height: 160px;
                margin-bottom: 1rem;
            }

            p {
                color: #4F4F4F;
            }

            &:hover { 
                a {
                    text-decoration: none;
                }

                p {
                    color: $secondary;
                    text-decoration: none;
                }
            }
        }
    }

    .STATISTICS_HOME {
        background-color: $secondary;
        padding-top: 2.5rem;
        padding-bottom: 4.5rem;

        p {
            color: white;
            font-family: "Muli Black";
            font-size: 14px;
            letter-spacing: 1.24px;
            margin-bottom: unset;
        }

        p.figure-large {
            font-size: 50px;
            line-height: 50px;
            text-transform: uppercase;
        }
    }

    .GET_STARTED_WITH_US {
        padding-bottom: 2.5rem;
        padding-top: 4.5rem;
        background-color: $primary;

        h2 {
            color: white;
            font-family: "Muli Black";
            text-transform: uppercase;
        }

        p {
            color: white;
        }
    }

    .btn-primary-inverted {
        font-family: "Muli Black";
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 1.41px;
        line-height: 20px;
        padding: 1rem 3rem 1rem 3rem;
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .home {
        .header {
            min-height: 75vh;
            height: unset;

            .align-self-top, .align-self-center, .align-self-end {
                position: relative;
                z-index: 2;
    
                .btn-primary {
                    font-size: 1.2em;
                }
    
                p {
                    font-size: 16px;
                }
            }
        }
    
        .PAYG_IS {
            margin-top: 2.5rem;
            margin-bottom: 2.5rem;
    
            .text-right{
                text-align: center !important;

                p {
                    font-size: 16px;
                    letter-spacing: 1.41px;
                    margin-right: unset;
                    margin-top: unset;
                    text-align: center;
                }
            }

            .text-left{
                text-align: center !important;

                p {
                    font-size: 16px;
                    letter-spacing: 1.41px;
                    margin-left: unset;
                    margin-top: unset;
                    text-align: center;
                }
            }
    
            .text-center {
                img {
                    margin-top: 10%;
                    transform: rotate(-45deg);
                }
            }
        }

        .STATISTICS_HOME {
            background-color: $secondary;
            padding-top: 2.5rem;
            padding-bottom: 4.5rem;
    
            .col-6 {
                min-height: 155px;

                p {
                    font-size: 14px;
                    letter-spacing: 1.24px;
                    margin-bottom: unset;
                }
        
                p.figure-large {
                    font-size: 2.5rem;
                    line-height: 2.5rem;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .home {
        .PAYG_IS {
            margin-top: unset;
            margin-bottom: unset;
    
            .text-right{
                p {
                    text-align: center;
                }
            }
    
            .text-left {
                p {
                    text-align: center;
                }
            }
        }
    
        .STATISTICS_HOME {
            background-color: $secondary;
            padding-top: 2.5rem;
            padding-bottom: 4.5rem;
    
            .col-6 {
                min-height: 155px;

                p {
                    font-size: 12px;
                }
        
                p.figure-large {
                    font-size: 2rem;
                    line-height: 2rem;
                }
            }

            

            
        }
    }
}

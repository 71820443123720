$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;

footer {

    .FOOTER_TOP {
        margin: 3rem 2rem;

        .margin-small {
            margin: 0.5rem 0;
        }
        p {
            font-family: Muli;
            font-size: 14px;
            color: $grey;
            margin-bottom: unset;
        }

        a {
            font-family: Muli;
            color: $grey;
            font-size: 14px;
            font-weight: bold;
            text-decoration: none;

            &:hover {
                color: $secondary;
                border-bottom: 4px solid $primary;
            }
        }

        .socials {
            img {
                height: 35px;
                width: 35px;
            }
        }

        .spacer {
            border-bottom: 2px solid $primary;
            width: 100%;
            margin: 5px 0px;
        }
    }

    .FOOTER_BOTTOM {
        background-color: #ADADAD;

        .nav-link {
            color: white;
            font-family: Muli;
            font-size: 14px;
            padding: .5rem 0;

            span {
                margin-left: 1rem;
                margin-right: 1rem;
            }
        }
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    footer {
        .FOOTER_TOP {
            margin: 2rem 1rem;
    
            .col-sm-3 {
                margin-bottom: 2rem;
            }

            .col-sm-2 {
                margin-bottom: 0.5rem;
            }
            
        }
    
        .FOOTER_BOTTOM {
            background-color: #ADADAD;
    
            .nav-link {
                color: white;
                font-family: Muli;
                font-size: 14px;
                padding: .5rem 0;
    
                span {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    footer {

        .FOOTER_TOP {
            margin: 3rem 2rem;

            .col-md-12 {
                margin-top: 1rem;
            }
    
            p {
                font-family: Muli;
                font-size: 14px;
                color: $grey;
                margin-bottom: unset;
            }
    
            a {
                font-family: Muli;
                color: $grey;
                font-size: 14px;
                font-weight: bold;
                text-decoration: none;
    
                &:hover {
                    color: $secondary;
                    border-bottom: 4px solid $primary;
                }
            }
    
            .spacer {
                border-bottom: 2px solid $primary;
                width: 100%;
                margin: 5px 0px;
            }
        }
    
        .FOOTER_BOTTOM {
            background-color: #ADADAD;
    
            .nav-link {
                color: white;
                font-family: Muli;
                font-size: 14px;
                padding: .5rem 0;
    
                span {
                    margin-left: 1rem;
                    margin-right: 1rem;
                }
            }
        }
    }
}

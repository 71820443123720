$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #616161;

.ABOUT_US {
    .header {
        background-image: url('../../assets/images/header_background_larger.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }

    .row.content {
        padding: 3% 10%;

        img {
            max-height: 370px;
        }

        .center-text {
            padding: 5rem 5rem;

            h2 {
                text-transform: uppercase;
                margin-bottom: 1.5rem;
            }

            p {
                font-family: Muli;
                color: #636363;
            }

            .btn-primary {
                font-family: "Muli Black";
                font-size: 16px;
                text-transform: uppercase;
                padding: 0.5rem 2.5rem;
            }

            
        }
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .ABOUT_US {
        .row.content {
            padding: 3% 10%;
    
            .center-text {
                padding: 5rem 5rem;
    
                h2 {
                    text-transform: uppercase;
                    margin-bottom: 1.5rem;
                }
    
                p {
                    font-family: Muli;
                    color: #636363;
                }
    
                .btn-primary {
                    font-family: "Muli Black";
                    font-size: 16px;
                    text-transform: uppercase;
                    padding: 0.5rem 2.5rem;
                }
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .ABOUT_US {
        .header {
            background-image: url('../../assets/images/header_background_larger.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 40vh;
    
            h1 {
                color: white;
                font-size: 25px;
                letter-spacing: 2.21px;
                font-family: "Muli Black";
                text-transform: uppercase;
                position: relative;
                top: 30%;
                transform: translateY(-30%);
            }
        }
    
        .row.content {
            padding: 3% 10%;
    
            .center-text {
                padding: 5rem 5rem;
    
                h2 {
                    text-transform: uppercase;
                    margin-bottom: 1.5rem;
                }
    
                p {
                    font-family: Muli;
                    color: #636363;
                }
    
                .btn-primary {
                    font-family: "Muli Black";
                    font-size: 16px;
                    text-transform: uppercase;
                    padding: 0.5rem 2.5rem;
                }
            }
        }
    }
}
$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #616161;

.OUR_WORK {

    .header {
        background-image: url('../../assets/images/header_background_larger.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }

    .work_title {
        padding-left: unset;
        padding-right: unset;
        position: relative;
        bottom: -50px;
        z-index: 1;

        .heading {
            background-color: $secondary;
            max-width: 20%;
            border-radius: 0 100px 100px 0;
            text-align: center;
    
            h2 {
                color: white;
                padding: 2rem 1.5rem;
                font-family: "Muli Black";
                font-size: 25px;
                letter-spacing: 2.21px;
                text-transform: uppercase;
            }
        }
    }

    .tile {
        background-repeat: no-repeat;
        background-size: cover;
        height: 480px;
        padding-right: unset;
        padding-left: unset;
        transition: max-width 0.3s ease-out;

        &:before {
            content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background-color: rgba(0,0,0,0.4);
        }

        img {
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            // max-width: 80%;
            // max-height: 50%;
            max-width: 400px;
            max-height: 150px;
        }

        .stats {
            display: none;

            p {
                color: white;
                font-size: 16px;
            }

            p.text-green {
                font-family: "Muli Black";
                font-size: 25px;
                letter-spacing: 2.21px;
                line-height: 25px;
                color: $primary;
            }

            .btn-primary {
                margin-top: 2rem;
                font-family: "Muli Black";
                font-size: 16px;
                letter-spacing: 1.41px;
                padding: 1rem 3rem;
                text-transform: uppercase;
                font-weight: bold;
            }
        }
        
        &:hover {
            max-width: 66.66%;

            img {
                display: none;
            }

            .stats {
                display: block;

                p.text-green {
                    font-family: "Muli Black";
                    font-size: 25px;
                    letter-spacing: 2.21px;
                    line-height: 25px;
                }

                img {
                    display: block;
                    margin: auto;
                    top: unset;
                    transform: unset;
                    padding-top: 3rem;
                    padding-bottom: 1rem;
                    max-height: 200px;
                }
            }
        }
        
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .OUR_WORK {
        .col-12 {
            .heading {
                max-width: 75%;
                border-radius: 0 100px 100px 0;
        
                h2 {
                    padding: 1.2rem 1rem;
                    font-size: 22px;
                }
            }
        }
    
        .tile {
            height: 50vh;
    
            img {
                max-height: 50%;
                max-width: 80%;
            }

            &:hover {
                max-width: 100%;
                height: unset;
                padding-bottom: 1rem;
    
                .stats {
                    display: block;
    
                    p.text-green {
                        font-family: "Muli Black";
                        font-size: 25px;
                        letter-spacing: 2.21px;
                        line-height: 25px;
                    }
    
                    img {
                        display: block;
                        margin: auto;
                        top: unset;
                        transform: unset;
                        padding-top: 3rem;
                        padding-bottom: 1rem;
                        max-height: 200px;
                    }
                }
            }
    
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .OUR_WORK {
        .col-12 {
            .heading {
                max-width: 40%;
            }
        }
    
        .tile {
            height: 380px;
    
            img {
                max-height: 50%;
                max-width: 80%;
            }
    
            .stats {
                display: none;
    
                .btn-primary {
                    margin-top: 2rem;
                    font-family: "Muli Black";
                    font-size: 16px;
                    letter-spacing: 1.41px;
                    padding: 1rem 3rem;
                    text-transform: uppercase;
                    font-weight: bold;
                }
            }

            &:hover {
                max-width: 66.66%;
    
                img {
                    display: none;
                }
    
                .stats {
                    display: block;
    
                    p.text-green {
                        font-family: "Muli Black";
                        font-size: 22px;
                        letter-spacing: unset;
                        line-height: 22px;
                    }
    
                    img {
                        display: block;
                        margin: auto;
                        top: unset;
                        transform: unset;
                        padding-top: 2rem;
                        padding-bottom: 1rem;
                        max-height: 150px;
                    }
                }
            }

        }
    }
}
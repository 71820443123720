$primary: #50BC9B;
$secondary: #F58123;

.OUR_CLIENTS {
    background-color: $secondary;
    padding: 4.5rem 0;

    h2 {
        color: white;
        text-transform: uppercase;
        font-size: 25px;
        font-family: "Muli Black";
        letter-spacing: 2.21px;
    }

    img {
        max-height: 100px; 
    }

    p {
        color: white;
        font-family: "Muli";
        font-size: 14px;
        letter-spacing: 1.24px;
        margin-top: 2rem;
    }

    .btn-primary {
        background-color: white;
        color: $secondary;
        margin-top: 2rem;
        font-family: "Muli Black";
        font-size: 16px;
        letter-spacing: 1.41px;
        padding: 1rem 3rem;
        text-transform: uppercase;
        font-weight: bold;

        &:hover { 
            border-color: white;
            text-decoration: underline;
        }
    }
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .OUR_CLIENTS {
        padding: 2.5rem 0;

        img {
            max-height: 60px; 
        }
    
        p {
            color: white;
            font-family: "Muli";
            font-size: 14px;
            letter-spacing: 1.24px;
            margin-top: 2rem;
        }
    
        .btn-primary {
            font-size: 12px;
            padding: 1rem 1rem;
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .OUR_CLIENTS {
        padding: 2.5rem 0;

        img {
            max-height: 60px;
        }
    
        p {
            color: white;
            font-family: "Muli";
            font-size: 14px;
            letter-spacing: 1.24px;
            margin-top: 2rem;
        }
    
        .btn-primary {
            font-size: 12px;
            padding: 1rem 1rem;
        }
    }
}
$primary: #50BC9B;
$secondary: #F58123;
$grey: #707070;
$light-grey: #616161;

.ABOUT_US {
    .header {
        background-image: url('../../assets/images/header_background_larger.png');
        background-repeat: no-repeat;
        background-size: cover;
        height: 40vh;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);
        }
    }

    .row.content {
        padding: 3% 10%;

        .center-text {
            padding: 0 5rem;
        }
    }

    .row.content.background-green {
        background-color: $primary;
        color: white;
    }

}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .ABOUT_US {
        .row.content {
            padding: 3% 10%;
    
            .center-text {
                padding: unset;
                text-align: center;
            }
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .ABOUT_US {
        .row.content {
            padding: 3% 10%;
    
            .center-text {
                padding: unset;
                text-align: center;
            }
        }
    }
}
$primary: #50BC9B;
$secondary: #F58123;

.SIGNUP {
    .background-img-full {
        background-image: url('../../assets/images/contact_bg_full.svg');
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 90vh;
        width: 100%;

        h1 {
            color: white;
            font-size: 25px;
            letter-spacing: 2.21px;
            font-family: "Muli Black";
            text-transform: uppercase;
            position: relative;
            top: 30%;
            transform: translateY(-30%);

            span {
                font-size: 35px;
                letter-spacing: 2.3px;
                color: $secondary;
            }
        }
    }

    .container {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .card {
            border-radius: 18px;

            .card-body {
                p {
                    color: $primary;
                    font-size: 20px;
                    font-family: "Muli Black";
                    letter-spacing: 1.77px;
                    margin-top: 1.5rem;
                    margin-bottom: unset;
                }
            }

            .contact-form {
                display: none;
                flex: 1 1 auto;
                min-height: 1px;
                padding: 1.25rem;

                label {
                    color: #798897;
                    font-family: "Muli";
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 1.05px;
                }
            }
        }
    }

    
}

// Phone
@media only screen and (min-device-width : 0px) and (max-device-width : 480px) {
    .SIGNUP {
        .background-img-full {
            h1 {
                padding-top: 2rem;
                top: unset;
                transform: unset;
            }
        }

        .container {
            top: unset;
            transform: unset;
        }
    }
}

// Tablet
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

}
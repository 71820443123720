@import "../admin.scss";

.sidebar {
    position: fixed;
    background-color: var(--bg-primary);
    transition: width 200ms ease;
    z-index: 2;

    .sidebar-nav {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
    }
      
    .nav-item {
        width: 100%;
    }
      
    .nav-item:last-child {
        margin-top: auto;
    }
      
    .nav-link {
        display: flex;
        align-items: center;
        height: 5rem;
        color: var(--text-primary);
        text-decoration: none;
        filter: grayscale(100%) opacity(0.7);
        transition: var(--transition-speed);
        padding: unset;
    }
      
    .nav-link:hover {
        filter: grayscale(0%) opacity(1);
        background: var(--bg-secondary);
        color: var(--text-secondary);
    }
      
    .link-text {
        display: none;
        margin-left: 1rem;
    }
      
    .nav-link svg {
        min-width: 2rem;
        margin: 0 1.5rem;
    }

    .fa-primary {
      color: $primary;
    }
    
    .fa-secondary {
      color: $secondary;
    }
    
    .fa-primary,
    .fa-secondary {
      transition: var(--transition-speed);
    }
    
    .logo {
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 1rem;
      text-align: center;
      color: $text-light;
      background: var(--bg-secondary);
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: 0.3ch;
      width: 100%;
    }
    
    .logo svg {
      transform: rotate(0deg);
      transition: transform var(--transition-speed);
    }
    
    .sidebar:hover .logo svg {
      transform: rotate(-180deg);
    }
}
  
/* Small screens */
@media only screen and (max-width: 600px) {
    .sidebar {
      bottom: 0;
      width: 100vw;
      height: 5rem;

      .logo {
        display: none;
      }
    
      .sidebar-nav {
        flex-direction: row;
      }
    
      .nav-link {
        justify-content: center;
      }
    }
  
    
  
    main {
      margin: 0;
    }
}
  
/* Large screens */
@media only screen and (min-width: 600px) {
    .sidebar {
      top: 0;
      width: 5rem;
      height: 100vh;

      .sidebar:hover {
        width: 16rem;
      }
    
      .sidebar:hover .link-text {
        display: inline;
      }
    }
}
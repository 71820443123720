@import "../admin.scss";

.WYSIWUG_Editor {
    display: block;

    .wrapper-class {
        background-color: #f4f4f4;
    }
    
    .editor-class {
        margin: 1% 10%;
        padding: 1%;
        border: 1px solid #e5e5e5;
        background-color: white;
        min-height: 80vh;
    }
}